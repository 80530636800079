import React from "react";

export default function Page404() {
	
	return (
		<main className="main main-login d-flex justify-content-center">
            <div className="intro-container w-100">
                <div className="logo-container">
                    <div className="logo">
                        <a href="login-docente.html">
                            <img src="assets/images/logo_opedialingue.png" alt="Opedia Lingue"/>
                        </a>
                    </div>
                    <div className="login-deco">
                        <img src="assets/images/login_cloud.png" alt="Opedia Lingue"/>
                    </div>
                </div>
            </div>
            <div className="form-container align-self-center p-4">
                <div className="form text-center">
                    <h1>Errore 404</h1>
                    <h3>Pagina non trovata...</h3>
                </div>
            </div>
        </main>
	);
  
}
