import React, { useState, useEffect } from "react";

import { useCore } from "../Core";

export default function Groups() {
	
	const { 
		authUser,
		isTeacher, 
		globalRoomUsers,
		setGlobalRoomUsers,
		activeGroups, 
		setActiveGroups, 
		setOpenActiveAttachment, 
		activeAttachments,
		setActiveAttachments,
		changeGroup,
		sendChangeGroupMsg,
		currentUserGroup,
		currentGroupDetails,
		setCurrentGroupDetails,
		currentQuiz,
		isRecording
	} = useCore();
	
	const [ openGroupsPanel, setOpenGroupsPanel ] = useState( false );	
	const [ groupsPause, setGroupsPause ] = useState( false );

	const [ tempRoomStatus, setTempRoomStatus ] = useState( {} );


	/* */

	useEffect( () => {

		if ( isTeacher ) {

			// TODO -- visto che il docente per visualizzare gli utenti di un gruppo deve entrare nella room
			// forse qui conviene costruire il dettaglio del gruppo direttamente dall'elenco globale degli utenti

			//console.log( '*** Updating groups...', globalRoomUsers );

			// update groups UI status
			
			let groups = {};

			for ( const [ k, user ] of Object.entries( globalRoomUsers ) ) {

				if ( user.room !== "0" ) {
					
					if ( !groups[ user.room ] ) {

						groups[ user.room ] = {
							id: user.room,
							users: {},
							isListening: false,
							isTalking: false,				
							listTalking: '--',
							hasAttach: false
						};

					}

					groups[ user.room ].users[ k ] = user;
				
				}

			}

			for ( const [ g, group ] of Object.entries( groups ) ) {

				let usersTalking = [];

				for ( const [ k, user ] of Object.entries( group.users ) ) {

					if ( user.isTalking ) usersTalking.push( user.name );

				}

				if ( usersTalking.length ) group.listTalking = usersTalking.join( ', ' );
				else group.listTalking = '--';

				if ( currentGroupDetails && currentGroupDetails.id === group.id ) {

					console.log( '*** Updating currentGroupDetails...', currentGroupDetails );
				
					if ( currentUserGroup !== "0" ) {
				
						if ( Object.entries( group.users ).length ) setCurrentGroupDetails( group );
						else teacherLeaveGroup();
					
					}
				
				}

			}

			console.log( 'Groups:', groups );

			if ( currentUserGroup !== "0" && !Object.entries( groups ).length ) teacherLeaveGroup();

			setActiveGroups( groups );		

		}
    
	}, [ globalRoomUsers ] );

	/* 
		teacherEnterGroup:
		Used by the teacher to enter in a group.
	*/

	const teacherEnterGroup = ( group ) => {

		console.log( 'teacherEnterGroup', group );

		setCurrentGroupDetails( group );

		changeGroup( authUser.name, group.id );

		sendChangeGroupMsg( authUser.name, group.id );

	};

	/* 
		teacherLeaveGroup:
		Used by the teacher to leave a group and return to the classroom.
	*/

	const teacherLeaveGroup = () => {

		console.log( '*** teacherLeaveGroup...' );

		setCurrentGroupDetails( null );

		changeGroup( authUser.name, "0" );

		sendChangeGroupMsg( authUser.name, "0" );

	};

	/* 
		handleDrop:
		Handle student dropped by the teacher in a group.
	*/

	const handleDrop = ( e, groupID ) => {
		
		e.stopPropagation();
		e.preventDefault();

		const userID = e.dataTransfer.getData( 'text' );

		console.log( 'Drop...', userID, groupID );

		let users = { ...globalRoomUsers };

		if ( !groupID ) {

			if ( Object.entries( activeGroups ).length > 0 ) {

				const lastKey = Object.keys( activeGroups )[ Object.keys( activeGroups ).length - 1 ];

				groupID = ( parseInt( lastKey ) + 1 ).toString();

			}
			else {

				groupID = "1";

			}

		}

		users[ userID ].room = groupID;
		
		setGlobalRoomUsers( users );

		sendChangeGroupMsg( userID, groupID );
		
	}

	/* */

	const handleDragOver = ( e ) => {
		
		e.stopPropagation();
		e.preventDefault();

	}

	/* 
		userExit:
		Set user in room 0 and send changin group message.
	*/

	const userExit = ( id, group ) => {
		
		console.log( 'userExit:', id, group );

		delete group.users[ id ];

		if ( currentUserGroup !== "0" ) {
			
			if ( Object.entries( group.users ).length ) setCurrentGroupDetails( group );
			else teacherLeaveGroup();
		
		}

		let users = { ...globalRoomUsers };

		users[ id ].room = "0";

		setGlobalRoomUsers( users );

		sendChangeGroupMsg( id, "0" );	
				  
	};

	/* */

	const deleteGroup = ( group ) => {

		console.log( 'deleteGroup:', group );

		if ( isRecording[ group.id ] ) {

			alert( 'Attenzione! Non è possibile eliminare un gruppo se vi è in corso una registrazione.' );

		}
		else {

			let r = window.confirm( 'Attenzione! Eliminare questo gruppo?' );

			if ( r ) {

				for ( const [ key, value ] of Object.entries( group.users ) ) {
					
					userExit( key, group );
					
				}

				let attachments = { ...activeAttachments };

				if ( attachments && attachments[ group.id ] ) {

					delete attachments[ group.id ];

					setActiveAttachments( attachments );

				}

			}

		}

	};

	/* */
	
	const tglPauseGroups = () => {

		console.log( 'tglPauseGroups' );	

		if ( !groupsPause ) {

			console.log( '****isRecording', isRecording );

			const groupIsRecording = ( group ) => group === true ;

			if ( isRecording.some( groupIsRecording ) ) {

				alert( 'Attenzione! Non è possibile mettere in pausa i gruppi se ci sono registrazioni in corso.' );

			}
			else {

				let tempstatus = {};

				let users = { ...globalRoomUsers };

				for ( const [ k, user ] of Object.entries( users ) ) {

					tempstatus[ k ] = user.room;

					user.room = "0";

					sendChangeGroupMsg( k, "0" );

				}

				setGlobalRoomUsers( users );
				setTempRoomStatus( tempstatus );

				setGroupsPause( true );
				setOpenGroupsPanel( false );

			}

		}
		else {

			console.log( '*** tempRoomStatus', tempRoomStatus );
			
			let users = { ...globalRoomUsers };

			for ( const [ k, user ] of Object.entries( users ) ) {

				user.room = tempRoomStatus[ k ];

				sendChangeGroupMsg( k, tempRoomStatus[ k ] );

			}

			setGlobalRoomUsers( users );

			setGroupsPause( false );
			setOpenGroupsPanel( true );

		}
            
	};

	/* */
	
	return (	
		
		isTeacher && !currentQuiz ?
		
			<>
				<footer className={ openGroupsPanel ? "footer footer-open" : "footer" }>
		            <div className="toolbar d-flex flex-row align-items-center justify-content-between px-5">
		               <div className="left d-flex flex-row align-items-center">
		                  <div className="toolbar__group-icon" onClick={ () => !groupsPause ? setOpenGroupsPanel( openGroupsPanel ? false : true ) : null }>
		                     <button className="trigger-groups"><i className="fas fa-th-large"></i></button>
		                  </div>
		                  <div className="toolbar__active-groups">
		                     <div className="badge">
		                         <span className="num">{ Object.entries( activeGroups ).length }</span> { " " }
		                         <span className="desc text-uppercase">gruppi</span>
		                     </div>
		                  </div>
		               </div>
		               <div className="right d-flex flex-row align-items-center">
		                  	<button className="toolbar__button mr-2" onClick={ () => !groupsPause ? setOpenGroupsPanel( openGroupsPanel ? false : true ) : null }>
		                     	<span><i className="fas fa-plus-circle"></i></span>
		                  	</button>

							{/*
								Object.entries( activeGroups ).length ?

									<button className={ groupsPause ? "toolbar__button button-pause active" : "toolbar__button button-pause" } onClick={ () => tglPauseGroups() }>
										<span><i className="fas fa-pause"></i></span>
									</button>

								:

									<button className={ groupsPause ? "toolbar__button button-pause active" : "toolbar__button button-pause" } onClick={ () => groupsPause ? tglPauseGroups() : null }>
										<span><i className="fas fa-pause"></i></span>
									</button>

							*/}							

		               </div>
		            </div>
		            <div className="footer-body">
		               <section className="groups">
		                  <div className="groups-wrapper">
		                     <div className="scrollable">
		                        <div className="row flex-nowrap">

									<div className="col-4 col-xl-3 d-flex flex-column">
		                              <div className="group-card p-3 flex-grow-1" onDragOver={ ( e ) => handleDragOver( e ) } onDrop={ ( e ) => handleDrop( e ) }>
		                                 <div className="card__wrapper">
		                                    <header className="card-toolbar pb-3 d-flex flex-row justify-content-between align-items-center">
		                                       <div className="left d-flex flex-row align-items-center">
												  <big><i className="fas fa-th-large"></i></big>
		                                          <span className="group-name ml-2">NUOVO GRUPPO</span>
		                                       </div>
		                                    </header>
		                                    <div className="group-card__body">
											   <div className="droppable-area text-center p-4 text-uppercase">
		                                          Trascina gli studenti qui per creare un nuovo gruppo
		                                       </div>
		                                    </div>
		                                 </div>
		                              </div>
		                           </div>
		                           
								   {		
								   		Object.entries( activeGroups ).map( ( [ k, group ] ) => {
												
											return (

												<div key={ k } className="col-4 col-xl-3 d-flex flex-column">

													<div className="group-card p-3 flex-grow-1"
														onDragOver={ ( e ) => handleDragOver( e, k ) }
														onDrop={ ( e ) => handleDrop( e, k ) }
														>
														<div className="card__wrapper">
															<header className="card-toolbar pb-3 border-bottom d-flex flex-row justify-content-between align-items-center">
															<div className="left d-flex flex-row align-items-center">
																<big><i className="fas fa-th-large"></i></big>
																<span className="group-name ml-2">GRUPPO { k }  { isRecording[ group.id ] ? <i className="fas fa-circle text-danger anim-blink ml-2"></i> : null }</span>
															</div>
															<div className="right d-flex flex-row align-items-center">
																<button 
																	className={ activeAttachments && activeAttachments[ group.id ] ? "card__button mr-4 active" : "card__button mr-4" } 
																	onClick={ activeAttachments && activeAttachments[ group.id ] ? () => setOpenActiveAttachment( group.id ) : null }>
																	<span><i className="fas fa-paper-plane"></i></span>
																</button>
																<button className="card__button mr-5" onClick={ () => deleteGroup( group ) }>
																	<span><i className="fas fa-trash-alt"></i></span>
																</button>
																<button className="card__button" onClick={ () => teacherEnterGroup( group ) }>
																	<span><i className="fas fa-search-plus"></i></span>
																</button>
															</div>
															</header>
															<div className="group-card__body">
																<div className="group-card__body__actions py-4 d-flex flex-row align-items-center justify-content-between">
																	<div className="left d-flex flex-row align-items-center">
																		<button className={ group.listTalking !== '--' ? "card__button is-talking active" : "card__button is-talking" }>
																			<span><i className="fas fa-comment-alt"></i></span>
																		</button>
																		<span className="student-names ml-2 flex-grow-1 text-nowrap">
																			{ group.listTalking }
																		</span>
																	</div>
																	<div className="right">
																		<div className="group-members d-inline-flex flex-row flex-nowrap align-items-center">
																			<span className="num">{ Object.entries( group.users ).length }</span>
																			<span className="icons ml-2"><i className="fas fa-users"></i></span>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												
												</div>		                           
								   
											)

										} )

									}

		                        </div>
		                     </div>
		                  </div>
		               </section>
		            </div>
		        </footer>

				{
					currentGroupDetails ?

						<div className={ Object.entries( currentGroupDetails ).length ? "group-card-modal" : "group-card-modal d-none" }>
							<div className="wrap d-flex flex-column">
								<div className="group-card zoom p-3 flex-grow-1">
									<div className="card__wrapper">
										<header className="card-toolbar pb-3 border-bottom d-flex flex-row justify-content-between align-items-center">
											<div className="left d-flex flex-row align-items-center">
												<big><i className="fas fa-th-large"></i></big>
												<span className="group-name ml-2">GRUPPO { currentGroupDetails.id }  { isRecording[ currentGroupDetails.id ] ? <i className="fas fa-circle text-danger anim-blink ml-2"></i> : null }</span>												
											</div>
											<div className="group-members d-inline-flex flex-row flex-nowrap align-items-center mr-auto ml-5">
												<span className="num">{ Object.entries( currentGroupDetails.users ).length }</span>
												<span className="icons ml-2"><i className="fas fa-users"></i></span>
											</div>
											<div className="right d-flex flex-row align-items-center">
												<button 
													className={ activeAttachments && activeAttachments[ currentGroupDetails.id ] ? "card__button mr-4 active" : "card__button mr-4" } 
													onClick={ activeAttachments && activeAttachments[ currentGroupDetails.id ] ? () => setOpenActiveAttachment( currentGroupDetails.id ) : null }>
													<span><i className="fas fa-paper-plane"></i></span>
												</button>
												<button className="card__button mr-5" onClick={ () => deleteGroup( currentGroupDetails ) }>
													<span><i className="fas fa-trash-alt"></i></span>
												</button>
												<button className="card__button" onClick={ () => teacherLeaveGroup() }>
													<span><i className="fas fa-search-minus"></i></span>
												</button>
											</div>
										</header>
										<div className="group-card__body">
											<div className="row py-4">

												{
													Object.entries( currentGroupDetails.users ).map( ( [ k, user ] ) => {
																
														return (

															<div key={ k } className="col-lg-4 col-md-6 my-2">
																<div className="card card-user d-flex flex-row justify-content-between align-items-center p-3">
																	<div className="user-icon-name d-flex align-items-center">
																		<span className={ user.isTalking ? "user-icon active" : "user-icon" }>
																			<i className="fas fa-comment-alt"></i>
																		</span>
																		<span className="user-name ml-2">
																			{ user.name }
																		</span>
																	</div>
																	<div className="user-status ml-auto">
																		<span className={ user.isActive ? "available" : "unavailable" }></span>
																	</div>
																	<div className="user-exit ml-4" onClick={ () => userExit( user.id, currentGroupDetails ) }>
																		<span>
																			<i className="fas fa-sign-out"></i>
																		</span>
																	</div>
																</div>
															</div>

														)

													} )
												}

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						
					:
						null
				}

		    </>
	        
	    :
	    
	    	null
        
	);
  
}
