import React, { useState, useEffect } from "react";

import { 
    useCore,
    recordAudio
} from "../Core";

let recorder; 
let audio;

export default function QuizFooter() {
	
    const { 
        currentQuiz,
        currentQuizIndex,
        setCurrentQuizIndex,
        currentExecution,
        setCurrentExecution
    } = useCore();

    const [ isRecordingAnswer, setIsRecordingAnswer ] = useState( false );

    /* */

    useEffect( () => {

        console.log( 'currentQuizIndex:', currentQuizIndex );
    
    }, [ currentQuizIndex ] );

    /* */

    const gotoNext = () => {

        let quizIndex = currentQuizIndex;
        quizIndex++;

        if ( quizIndex > currentQuiz.exercises.length - 1 ) setCurrentQuizIndex( 0 );
        else setCurrentQuizIndex( quizIndex );

    };

    /* */

    const gotoPrev = () => {

        let quizIndex = currentQuizIndex;
        quizIndex--;

        if ( quizIndex < 0 ) setCurrentQuizIndex( currentQuiz.exercises.length - 1 );
        else setCurrentQuizIndex( quizIndex );

    };

    /* */
    /*
    const recordAudio = () =>
    new Promise( async resolve => {

        const stream = await navigator.mediaDevices.getUserMedia( { audio: true } );

        const mediaRecorder = new MediaRecorder( stream );
        const audioChunks = [];

        mediaRecorder.addEventListener( "dataavailable", event => {
            
            audioChunks.push( event.data );

        });

        const start = () => mediaRecorder.start();

        const stop = () =>
        new Promise( resolve => {

            mediaRecorder.addEventListener( "stop", () => {

                const audioBlob = new Blob( audioChunks, { 'type': 'audio/mp3' } );
                const audioUrl = URL.createObjectURL( audioBlob );

                const reader = new window.FileReader();
                reader.readAsDataURL( audioBlob ); 

                reader.onloadend = function() {
                    
                    const audioBase64 = reader.result;

                    resolve( { audioBlob, audioUrl, audioBase64 } );

                }

            });

            mediaRecorder.stop();

        });

        resolve( { start, stop } );
    });
    */

    /* */

    const tglRecAnswer = async () => {

        if ( !isRecordingAnswer ) {

            console.log( 'Start Recording Answer...' );

            setIsRecordingAnswer( true );

            recorder = await recordAudio();
        
            recorder.start();

        }
        else {

            console.log( 'Stop Recording Answer...' );

            setIsRecordingAnswer( false );

            audio = await recorder.stop();

            console.log( 'Audio:', audio );

            let exec = { ...currentExecution };

            exec.exercises[ currentQuizIndex ].recAudio = audio.audioBase64;

            setCurrentExecution( exec );

        }

    };

    /* */
    
	return (	
		
        <footer className="footer footer-quiz">
            <div className="toolbar d-flex flex-row align-items-center justify-content-between px-5">

                <button className="btn btn-default btn-prev" onClick={ () => gotoPrev() }>
                    <i className="fas fa-arrow-left"></i>
                </button>

                {
                    currentQuiz.exercises && currentQuiz.exercises[ currentQuizIndex ].rec ?

                        <div className="d-flex">
                            <button type="button" className={ isRecordingAnswer ? "togglr togglr-rec mx-auto my-3 active" : "togglr togglr-rec mx-auto my-3" } onClick={ () => tglRecAnswer() }>
                                <i className="fas fa-circle"></i>
                                <span className="label">REC</span>
                            </button>
                            { 
                                currentExecution.exercises[ currentQuizIndex ].recAudio ? 
                                    <audio src={ currentExecution.exercises[ currentQuizIndex ].recAudio } className="my-3 mx-3" controlsList="nodownload" controls></audio>
                                :
                                    <audio src="#" className="my-3 mx-3" controlsList="nodownload" controls></audio>
                            }
                        </div>
                    :
                        null
                }
                
                <div className="quiz-step">
                    DOMANDA <span className="num">{ currentQuizIndex + 1 } / { currentQuiz.exercises ? currentQuiz.exercises.length : 0 } { " " } <i className="fas fa-list"></i></span>
                </div>

                <button className="btn btn-default btn-next" onClick={ () => gotoNext() }>
                    <i className="fas fa-arrow-right"></i>
                </button>

            </div>
        </footer>
        
	);
  
}
