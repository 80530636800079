
const mediaType = {
    audio: 'audioType',
    video: 'videoType',
    screen: 'screenType'
};

const _EVENTS = {
    exitRoom: 'exitRoom',
    openRoom: 'openRoom',
    startVideo: 'startVideo',
    stopVideo: 'stopVideo',
    startAudio: 'startAudio',
    stopAudio: 'stopAudio',
    startScreen: 'startScreen',
    stopScreen: 'stopScreen'
};

export default class RoomGlobal {

    constructor( socket, room_id, name, is_teacher, session_id, successCallback, updateCallback, errorCallback ) {

        console.log( '*** RoomGlobal constructor', room_id, name, is_teacher, session_id );
        
        this.name = name;
        this.is_teacher = is_teacher;
        this.session_id = session_id;

        this.successCallback = successCallback;
        this.updateCallback = updateCallback;
        this.errorCallback = errorCallback;

        this.socket = socket;
        this.room_id = room_id;

        this._isOpen = false;
        this.eventListeners = new Map();

        Object.keys( _EVENTS ).forEach( function( evt ){

            this.eventListeners.set( evt, [] );

        }.bind(this) );

        this.createRoom( room_id, session_id, is_teacher ).then( async function() {
            
            await this.join( session_id, room_id, this.name, this.is_teacher );
            this.initSockets();
            this._isOpen = true;

            //console.log( '*** RoomGlobal created...');
            
            this.successCallback();

        }.bind(this) );

    }

    ////////// INIT /////////

    async createRoom( room_id, session_id, is_teacher ) {

        console.log('*** createRoom Global', room_id, session_id, is_teacher);

        await this.socket.request('createRoom', {
            room_id,
            session_id,
            is_teacher
        }).then(async function (e) {
            
            console.log( '*** callback createRoom Global', e );

        }.bind(this)).catch(err => {
            
            console.log(err);
            alert( '[RoomGlobal] Error createRoom: ' + err );

        })

    }

    async join( session_id, room_id, name, is_teacher ) {

        console.log( '***join', session_id, room_id, name, is_teacher );

        this.socket.request('join', {     
            session_id,       
            room_id,
            name,
            is_teacher
        }).then(async function (e) {
            
            console.log( '***joined', e );

            const data = await this.socket.request('getRouterRtpCapabilities');

        }.bind(this)).catch(e => {
            
            console.log(e);
            //alert( '[RoomGlobal] Error join: ' + e );

            this.errorCallback( e );

        })
    }

    initSockets() {

        this.socket.on('disconnect', function () {
            this.exit(true);
        }.bind(this));

        this.socket.on('broadcastMessage', function (data) {
            //console.log('*** broadcastMessage', data);

            this.updateCallback( 'message', data );

        }.bind(this));

    }


    //////// MAIN FUNCTIONS /////////////

    sendMessage( data ) {

        //console.log('*** sendMessage', data);

        this.socket.emit( 'broadcastMessage', { data } );

    }

    exit(offline = false, isLogout = false) {

        console.log( 'RoomGlobal exit', offline, isLogout );

        let clean = function () {
            console.log( '*** clean start' );
            this._isOpen = false;
            /*
            this.socket.off('disconnect');
            this.socket.off('broadcastMessage');
            */
            this.socket.removeAllListeners();
            console.log( '*** clean end' );
        }.bind(this);

        if (!offline) {
            this.socket.request('exitRoom', {
                isLogout
            }).then(e => console.log(e)).catch(e => console.warn(e)).finally(function () {
                clean();
            }.bind(this));
        } else {
            clean();
        }

        this.event(_EVENTS.exitRoom);

    }

    ///////  HELPERS //////////

    async roomInfo() {
        let info = await this.socket.request('getMyRoomInfo');
        return info;
    }

    static get mediaType() {
        return mediaType;
    }

    event(evt) {
        if (this.eventListeners.has(evt)) {
            this.eventListeners.get(evt).forEach(callback => callback());
        }
    }

    on(evt, callback) {
        this.eventListeners.get(evt).push(callback);
    }


    //////// GETTERS ////////

    isOpen() {
        return this._isOpen;
    }

    static get EVENTS() {
        return _EVENTS;
    }
}