import React, { useState, useEffect } from "react";

import { useCore } from "../Core";

import QuizExercise from "../components/QuizExercise";
import QuizFooter from "../components/QuizFooter";

export default function MainBoard() {
	
	const { 
		onBlur,
		onFocus,
		onBeforeUnload,
		authUser,
		isTeacher, 
		globalRoomUsers,
		userKickOff,
		currentRoomUsers,
		currentUserGroup,
		currentQuiz
	} = useCore();

	const [ activeClassRoom, setActiveClassRoom ] = useState( {} );

	/* On Load */

	useEffect( () => {

		// Questi vengono instanziati qui perché devono essere attivi solo una volta entrati nella classe

		window.addEventListener( 'blur', onBlur );
		window.addEventListener( 'focus', onFocus );
		//window.addEventListener( 'beforeunload', onBeforeUnload );

		/* On UnLoad */

		return () => {

			window.removeEventListener( 'blur', onBlur );
			window.removeEventListener( 'focus', onFocus );
			//window.removeEventListener( 'beforeunload', onBeforeUnload );

		}

	}, [] );

	/* On Update */

	useEffect( () => {

		let classRoom = {};

		for ( const [ k, user ] of Object.entries( globalRoomUsers ) ) {

			if ( isTeacher ) {

				// teacher view

				if ( !user.isTeacher && user.room === "0" ) classRoom[ k ] = user;

			}
			else {

				// student view

				// TODO -- per evitare problema di utenti con stesso id qui bisogna escludere dall'elenco quelli che hanno il mio stesso name

				//if ( !user.isTeacher && user.room === currentUserGroup ) classRoom[ k ] = user;
				if ( !user.isTeacher && user.name !== authUser.name && user.room === currentUserGroup ) classRoom[ k ] = user;

			}

		}

		console.log( 'classRoom:', classRoom );
		//console.log( 'globalRoomUsers:', globalRoomUsers );

		setActiveClassRoom( classRoom );
    
	}, [ globalRoomUsers, currentUserGroup ] );

	/* */

	const handleDragStart = ( e, id ) => {

		console.log( 'Start drag...', id );
		
		e.dataTransfer.setData( 'text/plain', id ) ;

	}
	
	/* */

	return (	

		!isTeacher && currentQuiz ?

			(
				authUser.hasQuizDone ?

					<div className="my-5">
						<div className="my-5 text-center">
							<h1>Grazie per aver svolto il quiz!</h1>
							<h3>Per coretesia, resta in attesa che tutti gli altri consegnino e<br/> che il docente termini la sessione di quiz.</h3>
						</div>
					</div>

				:

					<>
						<QuizExercise/>
						<QuizFooter/>
					</>
			)

		:
				
			<main className={ currentQuiz ? "main main-dash dash-quiz" : "main main-dash" }>
				<div className="container-fluid">
					<div className="row">
					
					{		
						Object.entries( activeClassRoom ).map( ( [ k, user ] ) => {	
								
							return (
								
								<div key={ k } className="col-lg-4 col-md-6 my-2">
								
									<div className="card card-user d-flex flex-row justify-content-between align-items-center p-3" draggable="true" onDragStart={ ( e ) => handleDragStart( e, k ) }>
										<div className="user-icon-name d-flex align-items-center">

											{
												currentQuiz ?

													<span className={ user.hasQuizDone ?  "user-icon active" : "user-icon" }>
														<i className="fas fa-list"></i>
													</span>
												
												:

													<span className={ user.isTalking ?  "user-icon active" : "user-icon" }>
														<i className="fas fa-comment-alt"></i>
													</span>
											}

											<span className="user-name ml-2">
												{ user.name }
											</span>
										</div>
										
										{
											isTeacher ?
											
												<>
													<div className="user-status">
														<span className={ user.isActive ? "available" : "unavailable" }></span>
													</div>

													{
														user.hasQuizDone ?
															
															<div className="user-quiz-done">CONSEGNATO</div>
														
														:

															null
													}

													<div className="user-delete">
														<button type="button" className="delete" onClick={ () => userKickOff( user.id ) }>
															<span><i className="fas fa-times"></i></span>
														</button>
													</div>
												</>
											
											:
											
											null
										}
										
									</div>
									
								</div>
							
							)
									
						} )
					}
											
					</div>
				</div>
			</main>
			
	);
  
}
