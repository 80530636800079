import React from "react";

import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import MainBoard from "../components/MainBoard";
import Groups from "../components/Groups";
import Modals from "../components/Modals";
import Loader from "../components/Loader";

export default function UserDashboard() {
	
	return (
		
		<div id="app" className="d-flex flex-column">

			<Loader/>
		
			<Header/>
	        
	        <MainBoard/>
	        
	        <Sidebar/>
	        
	        <Groups/>

			<Modals/>
		
		</div>
	);
  
}
