import React, { useState } from "react";

import { useCore, youtubeParser } from "../Core";

export default function Modals() {

	const { 
		isTeacher, 
		isOffline,
		openSendAttachment, 
		setOpenSendAttachment, 
		openActiveAttachment, 
		setOpenActiveAttachment, 
		activeAttachments, 
		setActiveAttachments,
		currentError, 
		setCurrentError,
		activeGroups,
		sendAttachmentMsg,
		setOpenSideAttachment,
		activeRecordings,
		openActiveRecordings,
		setOpenActiveRecordings,
		previewRecording,
		setPreviewRecording,
		selectedRecordings,
		setSelectedRecordings,
		sendSaveRecordingsLocal
	} = useCore();

	const [ selectedRooms, setSelectedRooms ] = useState( [] );
	const [ attachmentDesc, setAttachmentDesc ] = useState( '' );

	/* */

	const tglDestinationRooms = ( id ) => {

		let rooms = [ ...selectedRooms ];

		const item_pos = rooms.indexOf( id );
		
		console.log( '*** id, item_pos', id, item_pos );

		if ( item_pos > -1 ) rooms.splice( item_pos, 1 ); 
		else rooms.push( id );

		console.log( '*** rooms', rooms );

		setSelectedRooms( rooms );

	};

	/* */

	const sendAttach = () => {

		if ( selectedRooms.length && attachmentDesc != '' ) {	

			let attach = { ...openSendAttachment };

			const desc = document.querySelector('#attachDesc').value;

			attach.desc = desc;

			let attachments = { ...activeAttachments };

			for ( let room of selectedRooms ) {

				attachments[ room ] = attach;

			}

			console.log( '*** attachments', attachments );

			setActiveAttachments( attachments );

			setOpenSendAttachment( null );

			setSelectedRooms( [] );

			setAttachmentDesc( '' );

			setOpenSideAttachment( false );

			sendAttachmentMsg( attachments );

		}

	};

	/* */

	function renderAttachmentPreview( item ) {

		switch( item.type ) {

			case 'doc':

			   return <iframe src={ item.url } className="doc-viewer"></iframe>;
   
			case 'audio':

			   return <audio controls controlsList="nodownload" src={ item.url }></audio>;
   
			case 'link':

			   return <iframe src={ item.url } className="doc-viewer"></iframe>;
   
			case 'video':

			   let videoPlayer = <a href={ item.url } target="_blank" rel="noreferrer"><img src={ item.preview_url } className="img-fluid" alt="Opedia" /></a>;

			   const youtubeID = youtubeParser( item.url );			   
			   
			   if ( youtubeID ) videoPlayer = <iframe type="text/html" width="100%" height="100%"  src={ 'https://www.youtube.com/embed/'+ youtubeID } frameborder="0"/>

			   return videoPlayer;  
   
			case 'img':
			   return <img src={ item.url } className="img-fluid" alt="Opedia" />;
   
			default:
			   return <i className="fas fa-file"></i>;
		}

	};

	/* */

	const tglSelectedRecordings = ( room_id, esercitazione_id, esercitazione ) => {

		let selected = { ...selectedRecordings };

		console.log( '****', room_id, esercitazione_id, esercitazione );

		if ( selected[ room_id ] ) {

			if ( selected[ room_id ][ esercitazione_id ] ) {
				
				delete selected[ room_id ][ esercitazione_id ];

				if ( Object.keys( selected[ room_id ] ).length === 0 && selected[ room_id ].constructor === Object ) delete selected[ room_id ];

			}
			else selected[ room_id ][ esercitazione_id ] = esercitazione;

		}
		else {

			selected[ room_id ] = {};
			selected[ room_id ][ esercitazione_id ] = esercitazione;

		}

		if ( Object.keys( selected ).length === 0 && selected.constructor === Object ) selected = null;

		console.log( 'Selected Recordings:', selected );

		setSelectedRecordings( selected );

	};

	/* */

	function renderRecordingsList( room, obj ) {

		return (
			
			Object.entries( obj ).map( ( [ k, esercitazione ] ) => {
												
				return (

					<li key={ k } className="d-flex flex-row justify-content-between align-items-center">
						<input type="checkbox" className="d-none" id={ 'rec-gruppo' + room + '_' + k } onChange={ () => tglSelectedRecordings( room, k, esercitazione ) } />
						<label className="left text-uppercase mr-3" htmlFor={ 'rec-gruppo' + room + '_' + k }>
							{
								room == "0" ?
									<span className="ml-2 text-nowrap"><i className="fas fa-users mr-2"></i>Classe / { k }</span>
								:
									<span className="ml-2 text-nowrap"><i className="fas fa-th-large mr-2"></i> Gruppo { room } / { k }</span>
							}
						</label>						
						<div className="right">
							<audio className="mr-3" src={ esercitazione.audio_file } controlsList="nodownload" controls></audio>
						</div>
					</li>

				)

			} )

		);
		
	}	

	/* */

	function renderModalClasses() {

		let classes = "modal-wrapper d-flex flex-column align-items-center justify-content-center";

		if ( openActiveAttachment ) classes = "modal-open modal-wrapper d-flex flex-column align-items-center justify-content-center";
		else if ( openSendAttachment || openActiveRecordings || previewRecording || isOffline || currentError !== "" ) classes = "modal-open modal-open-full modal-wrapper d-flex flex-column align-items-center justify-content-center";

		return classes;
		
	}

	/* */
	
	return (	

		<div className={ renderModalClasses() }>
		
			{
				isTeacher && openSendAttachment ?

					<div className={ openSendAttachment ? `modal d-flex flex-row flex-nowrap modal-invio-comanda modal-invio-comanda-type-${openSendAttachment.type}` : `modal d-none flex-row flex-nowrap modal-invio-comanda modal-invio-comanda-type-${openSendAttachment.type}` }>
						<aside className="modal__sidebar flex-column flex-grow-1 border-right">
							<header className="modal__sidebar-header py-3 text-center">
								<span><i className="fas fa-paper-plane"></i></span>
								<span className="ml-2">Seleziona destinatari</span>
							</header>
							<div className="modal__sidebar-body px-3">
								<ul className="recipients">

									<li className="d-flex flex-row justify-content-between align-items-center">
										<input type="checkbox" className="d-none" id="dest-classe" onChange={ () => tglDestinationRooms( "0" ) }/>
										<label className="left text-uppercase" htmlFor="dest-classe"><span className="ml-2">Classe</span></label>
										<div className="right"><i className="fas fa-users"></i></div>
									</li>

									{		
								   		Object.entries( activeGroups ).map( ( [ k, group ] ) => {
												
											return (

												<li key={ k } className="d-flex flex-row justify-content-between align-items-center">
													<input type="checkbox" className="d-none" id={ 'dest-gruppo' + k } onChange={ () => tglDestinationRooms( k ) }/>
													<label className="left text-uppercase" htmlFor={ 'dest-gruppo' + k }><span className="ml-2">Gruppo { k }</span></label>
													<div className="right"><i className="fas fa-th-large"></i></div>
												</li>

											)

										} )
									}
									
								</ul>
							</div>
							<footer className="modal__sidebar-footer"></footer>
						</aside>
						<div className="modal__main flex-column flex-grow-1">
							<header className="modal__main-header py-3 text-center position-relative">
								<span className="modal-title">{ openSendAttachment.name }</span>
								<button className="close-modal reset-button" onClick={ () => setOpenSendAttachment( null ) }><i className="far fa-times"></i></button>
							</header>
							<div className="modal__main-body px-3">
								<div className="attach-preview-area d-flex justify-content-center align-items-center">
									{ renderAttachmentPreview( openSendAttachment ) }	
								</div>							
							</div>
							<footer className="modal__main-footer p-3">
								<p className="text-center">Comanda</p>
								<textarea className="form-control comanda-text mb-3" id="attachDesc" onChange={ ( e ) => setAttachmentDesc( e.target.value ) }></textarea>
								<button className={ selectedRooms.length && attachmentDesc != '' ? "btn basic text-uppercase comanda-submit d-block mx-auto" : "btn basic text-uppercase comanda-submit d-block mx-auto disabled" } onClick={ () => sendAttach() }><span>Invia comanda</span></button>
							</footer>
						</div>
					</div>
				
				:			
					null
			}

			{
				activeAttachments && activeAttachments[ openActiveAttachment ] ?

					<div className={ openActiveAttachment ? `modal d-flex flex-row flex-nowrap modal-mostra-comanda modal-mostra-comanda-type-${activeAttachments[ openActiveAttachment ].type}`:`modal d-none flex-row flex-nowrap modal-mostra-comanda modal-mostra-comanda-type-${activeAttachments[ openActiveAttachment ].type}` }>
						<div className="modal__main flex-column flex-grow-1">
							<header className="modal__main-header py-3 text-center position-relative">
								<span className="modal-title">{ activeAttachments[ openActiveAttachment ].name }</span>
								<button className="close-modal reset-button" onClick={ () => setOpenActiveAttachment( null ) }><i className="far fa-times"></i></button>
							</header>
							<div className="modal__main-body px-3">
								<div className="attach-preview-area d-flex justify-content-center align-items-center">
									{ renderAttachmentPreview( activeAttachments[ openActiveAttachment ] ) }
								</div>
							</div>
							<footer className="modal__main-footer p-3">
								<p className="mb-0">{ activeAttachments[ openActiveAttachment ].desc }</p>
							</footer>
						</div>
					</div>
				:
					null
			}

			{
				currentError !== "" ?

					<div className="modal d-flex flex-row flex-nowrap modal-alert">
						<div className="modal__main flex-column flex-grow-1">
							<header className="modal__main-header py-3 text-center position-relative border-bottom">
								<strong className="modal-title">ATTENZIONE</strong>
								<button className="close-modal reset-button" onClick={ () => setCurrentError( "" ) }>
									<i className="far fa-times"></i>
								</button>
							</header>
							<div className="modal__main-body px-3 text-center py-4">
								<p className="mb-0">{ currentError }</p>
							</div>
						</div>
					</div>

				:
					null
			}

			{
				previewRecording ?

					<div className="modal d-flex flex-row flex-nowrap modal-alert">
						<div className="modal__main flex-column flex-grow-1">
							<header className="modal__main-header py-3 text-center position-relative border-bottom">
								<strong className="modal-title">Registrazione Effettuata</strong>
								<button className="close-modal reset-button" onClick={ () => setPreviewRecording( null ) }>
									<i className="far fa-times"></i>
								</button>
							</header>
							<div className="modal__main-body px-3 text-center py-4">

								<p>Ascola la registrazione appena effettuata. La registrazione verrà mantenuta per tutta la lezione. Potrai decidere quali registrazioni salvare prima di chiudere facendo "SALVA SVOLGIMENTO".</p>								
								<audio src={ previewRecording } controlsList="nodownload" controls></audio>
								
							</div>
						</div>
					</div>

				:
					null
			}

			{
				activeRecordings && openActiveRecordings ?

					<div className="modal d-flex flex-row flex-nowrap">
						<div className="modal__main flex-column flex-grow-1">
							<header className="modal__main-header py-3 text-center position-relative border-bottom">
								<strong className="modal-title">Registrazioni Effettuate</strong>
								<button className="close-modal reset-button" onClick={ () => setOpenActiveRecordings( false ) }>
									<i className="far fa-times"></i>
								</button>
							</header>
							<div className="modal__main-body px-3 text-center py-4">
								
								<ul className="recipients">

									{
										Object.entries( activeRecordings ).map( ( [ k, room ] ) => {

											return ( 
												renderRecordingsList( k, room )
											)
								
										} )
									}

								</ul>

							</div>
							<div className="modal__main-footer p-3">

								<button className={ selectedRecordings ? "btn basic text-uppercase btn-block save" : "btn basic text-uppercase btn-block save disabled" } onClick={ () => sendSaveRecordingsLocal() }>
                                    <span>Salva in locale</span>
                                </button>

							</div>
						</div>
					</div>

				:
					null
			}

			{
				isOffline ?

					<div className="modal d-flex flex-row flex-nowrap modal-alert">
						<div className="modal__main flex-column flex-grow-1">
							<header className="modal__main-header py-3 text-center position-relative border-bottom">
								<strong className="modal-title">ATTENZIONE</strong>
							</header>
							<div className="modal__main-body px-3 text-center py-4">
								<p className="mb-0">Connessione internet assente!</p>
							</div>
						</div>
					</div>

				:
					null
			}

		</div>
        
	);
  
}
