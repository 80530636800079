import React from "react";

import { useCore } from "../Core";

export default function Loader() {
	
    const { 
        isLoading
    } = useCore();
    
	return (	
		
        isLoading ? <div id="loader"><i class="fal fa-sync fa-spin"></i></div> : null
        
	);
  
}
